import React from 'react'
// import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import PreviewSchool from '../components/previewSchool'
import RelatedArticles from '../components/relatedArticles'

const SchoolPage = () => {
    //   const data = useStaticQuery(graphql`{
    //   desktopImage: file(relativePath: {eq: "images/udine.jpg"}) {
    //     childImageSharp {
    //       gatsbyImageData(transformOptions: {cropFocus: SOUTH}, layout: FULL_WIDTH)
    //     }
    //   }
    //   mobileImage: file(relativePath: {eq: "images/udine.jpg"}) {
    //     childImageSharp {
    //       gatsbyImageData(
    //         width: 207
    //         height: 368
    //         transformOptions: {cropFocus: SOUTH}
    //         layout: CONSTRAINED
    //       )
    //     }
    //   }
    // }
    // `)

    //   const backgroundImages = [
    //     data.mobileImage.childImageSharp.gatsbyImageData,
    //     {
    //       ...data.desktopImage.childImageSharp.gatsbyImageData,
    //       media: `(min-width: 48em)`,
    //     },
    //   ]

    return (
        <>
            <GatsbySeo
                title="Vinskola - lär dig mer om vin!"
                description="Vinologik är en digital vinskola för nybörjare och består av flera kurser som går igenom olika delar inom vin på ett lättsamt och enkelt sätt. Du behöver inga förkunskaper för att börja!"
            />
            <Hero
                heading="Din digitala vinskola"
                body="Lär dig mer om vin i vår digital vinskola för nybörjare och består av flera kurser som går igenom olika delar inom vin på ett lättsamt och enkelt sätt. <br/> <br/> Du kommer bland annat få lära dig om hur man provar vin, hur vin tillverkas, vilka stilar det finns och massa annat! Dessutom har vi quiz där du kan sätta dina nya kunskaper på prov. <br/> <br/> Du behöver inga förkunskaper för att börja!"
            />
            <PreviewSchool firstOpen={true} />
            <RelatedArticles headline="Inte redo för skolbänken än?" />
        </>
    )
}

export default SchoolPage
